import {memo, useMemo} from 'react';
import styled, {css} from 'styled-components';
import { useSelector } from 'react-redux';
import {
    useLogoColorVariable,
} from '@repo/ui';
import TopRow from '@/components/Menu/shared/TopRow';

export const underlineAnimationColorCss = css`
    .underline-animation {
        &:before {
            background: var(--secondary-color);
        }
    }

    .underline-animation-lowercase {
        &:before {
            background: var(--secondary-color);
        }
    }
`


const StyledWrapper = styled.div`
    ${underlineAnimationColorCss};
`

const Menu = ({ menu: menuData, globals }) =>  {
    const menu = menuData?.length > 0 ? menuData[0] : null
    const menuItems = menu?.menuItems || [];
    const firstMenuItem = (menuItems[0] || '');
    const contactButtonText = menu?.contactButtonText || ''
    const contactButtonLink = menu?.contactButtonLink || []
    const { open: isMenuOpen } = useSelector((state) => state.menu);

    const finalUnderlineColor = useLogoColorVariable()

    return (
        <StyledWrapper $underlineColor={finalUnderlineColor}>
            <TopRow
                firstMenuItem={firstMenuItem}
                menuItems={menuItems}
                contactButtonText={contactButtonText}
                contactButtonLink={contactButtonLink}
                globals={globals}
            />
        </StyledWrapper>
    );
}

export default memo(Menu)
