import { createSlice } from '@reduxjs/toolkit';

export const webGLSlice = createSlice({
	name: 'webgl',
	initialState: {
		cameraDistance: 600,
		fov: 75,
	},
	reducers: {
		setCameraDistance: (state, action) => void (state.cameraDistance = action.payload),
		setFOV: (state, action) => void (state.fov = action.payload),
	},
});

export const { setCameraDistance, setFOV } = webGLSlice.actions;

export default webGLSlice.reducer;
