import { gsap } from 'gsap'

gsap.config({ nullTargetWarn: false })

export function setTransitionCurvedOpenVisibility(visible = false) {
    return gsap.set('.transition-curved-open', { autoAlpha: visible ? 1 : 0 })
}

export function setTransitionCurvedSvgWrapperPosition(inView = false, callback = () => {}) {
    return gsap.set('.transition-curved-svg-wrapper', { x: inView ? 0 : '-100%', onComplete: callback })
}

export function moveCurtainBlock(inView = false, callback) {
    return gsap.to('.curtain-page-transition-block', {
        y: inView ? 0 : '-101%',
        ease: 'power2.out',
        duration: 0.8,
    })
}

export function setCurtainBlockPosition(inView = false, callback) {
    return gsap.set('.curtain-page-transition-block', {
        y: inView ? 0 : '-100%',
    })
}

export function transformCurvedOpenToTargetPath(path) {
    return gsap.to('.transition-curved-open', {
        ease: 'power1.out',
        duration: 0.2,
        attr: {
            d: path
        },
    })
}

export function transformCurvedOpenToFinalPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-open', {
        duration: 0.39,
        attr: {
            d: path
        },
        onComplete: callback
    })
}

export function setTransitionCurvedCloseVisibility(visible = false, callback = () => {}) {
    return gsap.set('.transition-curved-close', { autoAlpha: visible ? 1 : 0, onComplete: callback })
}

export function transformCurvedCloseToTargetPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-close', {
        ease: 'power1.out',
        duration: 0.2,
        attr: {
            d: path
        },
        onComplete: callback
    })
}

export function transformCurvedCloseToInitialPath(path, callback = () => {}) {
    return gsap.to('.transition-curved-close', {
        duration: 0.35,
        attr: {
            d: path
        }
    })
}

export function moveTopBar(inView = false, delay = 0, callback = () => {}) {
    return gsap.to('.menu-top-row', {
        y: inView ? 0 : '-100%',
        duration: 0.15,
        ease: 'power2.in',
        delay
    })
}

export function linkPageTransitionAnimation(svgPaths, callback) {
    return gsap.timeline({
        paused: true,
        onComplete: () => {
            moveTopBar(true)
        }
    })
        .add(setTransitionCurvedOpenVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(moveTopBar(false))
        .add(moveCurtainBlock(true))
        .add(transformCurvedOpenToTargetPath(svgPaths.open.targetPath), '<')
        .add(transformCurvedOpenToFinalPath(svgPaths.open.finalPath, () => { callback() }), '-=0.5')
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true))
        .add(moveCurtainBlock(false), '+=1')
        .add(transformCurvedCloseToTargetPath(svgPaths.close.targetPath), '<')
        .add(transformCurvedCloseToInitialPath(svgPaths.close.initialPath), '-=0.5')
        .add(setTransitionCurvedSvgWrapperPosition(false))
}

export function initialTransitionAnimation(svgPaths, callback) {
    return gsap.timeline({
        paused: true,
        onComplete: () => {
            moveTopBar(true)
        }
    })
        .add(setTransitionCurvedOpenVisibility(true))
        .add(setTransitionCurvedSvgWrapperPosition(true))
        .add(setCurtainBlockPosition(true))
        .add(setTransitionCurvedOpenVisibility(false))
        .add(setTransitionCurvedCloseVisibility(true, () => { callback() }))
        .add(moveCurtainBlock(false), '+=1')
        .add(transformCurvedCloseToTargetPath(svgPaths.close.targetPath), '<')
        .add(transformCurvedCloseToInitialPath(svgPaths.close.initialPath), '-=0.5')
        .add(setTransitionCurvedSvgWrapperPosition(false))
        .add(setTransitionCurvedSvgWrapperPosition(false))
}
