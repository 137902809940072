import styled from 'styled-components';
import {useRef} from 'react';
import {Icon, useQueryElement} from '@repo/ui';
import { useRouter } from 'next/router';
import {ICONS} from "@repo/utils/constants";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "@repo/ui/themeSettings";

const StyledWrapper = styled.div`
    cursor: pointer;
    width: ${getCssSizeValue(106, BREAKPOINTS.MOBILE)};
    position: relative;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(131, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(131)};
    }
`;

const StyledLogoWrapper = styled.div`
    .icon {
        max-width: unset;
    }
`;

const Logo = ({ firstMenuItem }) => {
    const { push } = useRouter();
    const logoSvgWidth = useQueryElement('#logo-svg')?.clientWidth || 200;

    const wrapperRef = useRef(null);
    const handleOnClick = () => {
        push('/', '/', { scroll: false });
    };

    return (
        <StyledWrapper
            onClick={handleOnClick}
            ref={wrapperRef}
            width={logoSvgWidth}
        >
            <StyledLogoWrapper className="logo-wrapper" width={logoSvgWidth}>
                <Icon name={ICONS.PLEO_DESIGN_MENU_LOGO} className='no-color' />
            </StyledLogoWrapper>
        </StyledWrapper>
    );
};

export default Logo;
