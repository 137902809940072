import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isModalVisible: false,
	modalData: {
		header: 'Modal header',
		description: 'Modal description',
		closeButtonText: 'Close',
		openButtonText: '',
		externalUrl: '',
	},
};

export const modalSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setIsModalVisible: (state, payload) => {
			state.isModalVisible = payload.payload;
		},
		setModalData: (state, payload) => {
			state.modalData = payload.payload;
		},
	},
});

export const { setIsModalVisible, setModalData } = modalSlice.actions;

export default modalSlice.reducer;
