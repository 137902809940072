import React, {memo, useEffect, useMemo} from 'react';
import {Cookies, Cursor, Modal, usePageTransitionContext, useScrollContext} from "@repo/ui";
import {useRouter} from "next/router";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer/Footer";
import {setIsModalVisible} from "@/state/modalSlice";
import {useDispatch, useSelector} from "react-redux";

const Layout = ({ cookies, children, menu, footer, data, globals })  => {
	const scroll = useScrollContext()
	const { transitionCompleted } = usePageTransitionContext()
	const router = useRouter()
	const isProjectSite = useMemo(() => {
		const path = router.asPath
		return path.split('/').length === 3 && path.includes('projects')
	},[router.asPath])
// 	useEffect(() => {
// 		console.log('%cHello from PLEO!', 'font-size: 20px; color: #00AEEF;');
// 		console.log('%cDo not type showYourSecret() in console ;)!', 'font-size: 20px; color: red;');
// 	}, []);
// 	useEffect(() => {
// 		window.showYourSecret = () => {
// 			console.log('%cEurope is White!', 'font-size: 40px; color: white; background: black');
// 			console.log(`
//
//  .----------------.  .----------------.  .----------------.  .----------------.     .----------------.  .----------------.  .----------------.  .----------------.  .----------------.  .-----------------.
// | .--------------. || .--------------. || .--------------. || .--------------. |   | .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
// | |   ______     | || |   _____      | || |  _________   | || |     ____     | |   | |  ________    | || |  _________   | || |    _______   | || |     _____    | || |    ______    | || | ____  _____  | |
// | |  |_   __ \\   | || |  |_   _|     | || | |_   ___  |  | || |   .'    \`.   | |   | | |_   ___ \`.  | || | |_   ___  |  | || |   /  ___  |  | || |    |_   _|   | || |  .' ___  |   | || ||_   \\|_   _| | |
// | |    | |__) |  | || |    | |       | || |   | |_  \\_|  | || |  /  .--.  \\  | |   | |   | |   \`. \\ | || |   | |_  \\_|  | || |  |  (__ \\_|  | || |      | |     | || | / .'   \\_|   | || |  |   \\ | |   | |
// | |    |  ___/   | || |    | |   _   | || |   |  _|  _   | || |  | |    | |  | |   | |   | |    | | | || |   |  _|  _   | || |   '.___\`-.   | || |      | |     | || | | |    ____  | || |  | |\\ \\| |   | |
// | |   _| |_      | || |   _| |__/ |  | || |  _| |___/ |  | || |  \\  \`--'  /  | |   | |  _| |___.' / | || |  _| |___/ |  | || |  |\`\\____) |  | || |     _| |_    | || | \\ \`.___]  _| | || | _| |_\\   |_  | |
// | |  |_____|     | || |  |________|  | || | |_________|  | || |   \`.____.'   | |   | | |________.'  | || | |_________|  | || |  |_______.'  | || |    |_____|   | || |  \`._____.'   | || ||_____|\\____| | |
// | |              | || |              | || |              | || |              | |   | |              | || |              | || |              | || |              | || |              | || |              | |
// | '--------------' || '--------------' || '--------------' || '--------------' |   | '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
//  '----------------'  '----------------'  '----------------'  '----------------'     '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'
//
//  `);
// 		};
// 	}, []);

	const dispatch = useDispatch()

	const modalData = useSelector((state) => state.modal.modalData);
	const isModalVisible = useSelector((state) => state.modal.isModalVisible);


	return (
		<Cursor eventSource={scroll.main} transitionCompleted={transitionCompleted} >
			<Menu menu={menu} globals={globals} />
			<Cookies data={cookies} />
			<Modal
				isVisible={isModalVisible}
				handleCloseButton={() => dispatch(setIsModalVisible(false))}
				data={{
					header: modalData.header,
					text: modalData.description,
					buttonText: modalData.closeButtonText,
					buttonTextOpen: modalData.openButtonText,
					externalUrl: modalData.externalUrl
				}}
			/>
			{children}
			{!isProjectSite && <Footer data={footer}/>}
		</Cursor>
	);
}

export default memo(Layout)
