import styled from 'styled-components';
import Logo from '@/components/Menu/shared/Logo';
import {
    Button,
    LetsTalkButton,
    Link,
    useScrollContext,
    useSpecifyColor,
    useHideTopBarOnScroll,
    usePageTransitionContext, StoryButton,
} from "@repo/ui";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "@repo/ui/themeSettings";
import { motion} from "framer-motion";
import {useEffect, useMemo} from "react";
import {moveTopBar} from "@/components/Menu/shared/animation";
import {CONTENT_TYPES, CURSOR_TYPES} from "@repo/utils/constants";
import LanguageChange from "@/components/Menu/shared/LanguageChange";
import {useRouter} from "next/router";
import {setIsModalVisible, setModalData} from "@/state/modalSlice";
import {useDispatch} from "react-redux";

const StyledTopRow = styled(motion.div)`
	z-index: 99;
	display: flex;
	justify-content: space-between;
	width: 100%;
    padding-top: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    position: fixed;
    transition: all .3s;
    background: transparent;
    transform: translateY(-100%);
    
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.TABLET)};
	}
`;

const StyledRightPartWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${getCssSizeValue(41, BREAKPOINTS.DESKTOP)};
    margin-right: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-right: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }
`

const StyledContactButtonWrapper = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display: initial;
    }
`

const StyledLogoWrapper = styled.div`
	z-index: 1000;
`;

const StyledSingleNavItem = styled(Button)`
    text-transform: lowercase;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: var(--secondary-color);
`

const TopRow = ({ firstMenuItem, contactButtonText, contactButtonLink, menuItems = [], globals }) => {
    const router = useRouter()
    const modalHeader = globals?.modalHeader || ''
    const modalDescription = globals?.modalDescription || '';
    const modalButtonTextClose = globals?.buttonTextClose || '';
    const modalButtonTextOpen = globals?.buttonTextOpen || '';

    const dispatch = useDispatch()
    const { locale } = useRouter()

    const bgColor = useSpecifyColor()
    const contactButtonLinkSlug = contactButtonLink ? `/${contactButtonLink?.slug}` : '';
    const { asPath } = useRouter()
    const normalizedPath = useMemo(() => asPath === "/" ? "home" : asPath, [asPath])

    const { instance: { __lenis } } = useScrollContext()
    useHideTopBarOnScroll()

    const handleContactClick = () => {
        if (router.asPath.includes('contact')) {
            __lenis.scrollTo(0)
        } else {
            router.push(contactButtonLinkSlug, contactButtonLinkSlug, { scroll: false })
        }
    }

    return (
        <StyledTopRow
            $bgColor={bgColor}
            id="menu-top-row"
            className="menu-top-row px-main"
        >
            <StyledLogoWrapper>
                <Logo firstMenuItem={firstMenuItem}/>
            </StyledLogoWrapper>
            <StyledRightPartWrapper>
                {
                    menuItems.map((item, index) => {
                        const id = item?.id || index
                        const text = item?.link?.menuContext?.previewText || ''
                        const slug = item?.link?.slug || ''
                        const href = slug === 'home' ? '/' : `/${slug}`
                        const isActiveRoute = normalizedPath.includes(slug)
                        const typename = item?.link?.__typename || ''

                        const isBlogPage = typename === CONTENT_TYPES.GROUP_SINGLE_PAGE_RECORD

                        return (
                            isBlogPage ? (
                                <div
                                    key={id}
                                    onClick={() => {
                                    dispatch(setModalData({
                                        header: modalHeader,
                                        description: modalDescription,
                                        closeButtonText: modalButtonTextClose,
                                        openButtonText: modalButtonTextOpen,
                                        externalUrl: `${process.env.PLEO_GROUP_URL}${locale=== 'pl' ? '/pl/' :'/'}news`
                                    }))
                                    dispatch(setIsModalVisible(true))
                                }}>
                                    <StyledSingleNavItem
                                        variant='unstyled'
                                        key={id}
                                        data-cursor={CURSOR_TYPES.LINK}
                                        className={`underline-animation ${isActiveRoute ? 'active' : ''}`}
                                    >
                                        {text}
                                    </StyledSingleNavItem>
                                </div>
                                ) : (
                                <Link href={href} key={id}>
                                    <StyledSingleNavItem
                                        variant='unstyled'
                                        key={id}
                                        data-cursor={CURSOR_TYPES.LINK}
                                        className={`underline-animation ${isActiveRoute ? 'active' : ''}`}
                                    >
                                        {text}
                                    </StyledSingleNavItem>
                                </Link>
                                )
                        )
                    })
                }
                <StoryButton/>
                <StyledContactButtonWrapper>
                    <LetsTalkButton onClick={handleContactClick} pleo="design">
                        {contactButtonText}
                    </LetsTalkButton>
                </StyledContactButtonWrapper>
                <LanguageChange />
            </StyledRightPartWrapper>
        </StyledTopRow>
    );
};

export default TopRow;
