import { configureStore } from '@reduxjs/toolkit';
import showCookiesSlice from './showCookiesSlice';
import webGLSlice from './webGLSlice';
import menuSlice from './menuSlice';
import modalSlice from './modalSlice'

export default configureStore({
	reducer: {
		showCookies: showCookiesSlice,
		menu: menuSlice,
		webGL: webGLSlice,
		modal: modalSlice
	},
});
