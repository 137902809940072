import React, { memo } from 'react';
import styled from 'styled-components';
import {getAspectRatioFromImage, getCssSizeValue, getFontSize, getSlug} from "@repo/utils";
import {BREAKPOINTS, THEME_COLOR_SCHEMES} from "@repo/ui/themeSettings";
import {Icon as IconComponent, Link, Video, Button, Reveal, Image, useIsMobile, useScrollContext} from "@repo/ui";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import MockupVideo from '../../assets/video/pleo-showreel-short_compressed.mp4'
import {useRouter} from "next/router";
import PleonianinRive from "@/components/Footer/PleonianinRive";
import LanguageChange from "@/components/Menu/shared/LanguageChange";
import {useDispatch, useSelector} from "react-redux";
import {setShowCookies} from "@/state/showCookiesSlice";

const Wrapper = styled.footer`
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: 100%;

    .video{
        position: relative;
        aspect-ratio: 390/197;
        width: inherit;
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            aspect-ratio: 772/390;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            aspect-ratio: 1920/744;
        }
    }
    
`

const Content = styled.div`
    --text-color: ${({ theme }) => theme.colors.black };
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    row-gap: ${getCssSizeValue(51, BREAKPOINTS.MOBILE)};
    --padding-top: ${getCssSizeValue(64, BREAKPOINTS.MOBILE)};
    --padding-bottom: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    overflow: hidden;
    
    a, button{
        color: var(--text-color);
        &:before{
            background: var(--text-color);
        }
    }
    
    a{
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        flex-wrap: wrap;
       
        &.arrow{
            width:fit-content;
            min-width: ${getCssSizeValue(137, BREAKPOINTS.MOBILE)};
            column-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        }

        div.arrow-up{
            padding: 2px;
            width: ${getFontSize(15, BREAKPOINTS.MOBILE)};
            transform: translateY(0%) rotate(45deg);
            transition: transform var(--transition-duration) var(--ease);
        }

        &:hover{
            div.arrow-up{
                transform: translateX(50%) translateY(-50%) rotate(45deg);
            }
        }
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tabet || 768 }px) {
        --padding-top: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
        --padding-bottom: ${getCssSizeValue(28, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        --padding-top: ${getCssSizeValue(61, BREAKPOINTS.DESKTOP)};
        --padding-bottom: var(--padding-top);
        a.arrow{
            min-width: 150px;
        }
    }
`

const Row = styled.div`
    position: relative;
    width: fit-content;
    display: flex;
    
    &.navigation{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    }
    
    &.bottom{
        flex-direction: column;
        width: 100%;
        align-items: start;
        column-gap: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
        row-gap: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
        button, p {
            font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        }
    }
    
    &.pair{
        row-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        flex-direction: column;
    }
    
    &.ui{
        order: 2;
    }
    
    &.contact{
        flex-direction: column-reverse;
        width: 100%;
    }
    
    &.justify-between {
        width: 100%;
        justify-content: space-between;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        &.bottom{
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }
        
        &.pair{
            column-gap: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
            flex-direction: row;
        }

        &.contact{
            justify-content: space-between;
            flex-direction: row-reverse;
        }
        &.policies{
            margin-left: auto;
            order: 3;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        &.navigation{
            width: fit-content;
            column-gap: ${getCssSizeValue(117, BREAKPOINTS.DESKTOP)};
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            a{
                font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                --size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            }
        }
        
        &.bottom{
            column-gap: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
            flex-direction: row;
            a,button{
                font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            }
        }
        
        
        &.pair{
            column-gap: ${getCssSizeValue(75, BREAKPOINTS.DESKTOP)};
        }
        
        &.ui{
            order: 0;
            margin-right: ${getCssSizeValue(240, BREAKPOINTS.DESKTOP)};
        }
         &.contact{
             flex-direction: row;
             justify-content: start;
             width: fit-content;
         }
    }
    
`

const Icon = styled(IconComponent)`
    &.arrow-right{
        width: 18px;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};
    &.lower{
        text-transform: lowercase;
    }
`

const LogosWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    height: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
        gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        flex-wrap: initial;
        margin-bottom: unset;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(22)};
        gap: ${getCssSizeValue(25)};
    }
`

const StyledImage = styled(Image)`
    cursor: pointer;
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    height: 100%;
    width: auto;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
    }
`

const Logos = ({ logos = []}) => {
    const onClick = (link) => {
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <LogosWrapper $numberOfItems={logos.length}>
            {logos.map((item) => {
                const src = item?.logo?.url || ''
                const alt = item?.logo?.alt || ''
                const aspectRatio = getAspectRatioFromImage(item?.logo)
                const link = item?.logoLink || ''
                return (
                    <StyledImage
                        onClick={() => onClick(link)}
                        key={item?.id || ''}
                        fit
                        src={src}
                        alt={alt}
                        sizes={'100vw'}
                        $aspectRatio={aspectRatio}
                    />
                )
            })}
        </LogosWrapper>
    )
}

const Footer = ({ data = {} }) => {

    const {
        socials = [],
        links = [],
        contact= {},
        buttons = {},
        logos = []
    } = data;

    const buttonTop = buttons?.top || ''
    const buttonCookies = buttons?.cookies || ''
    const buttonPolicy = buttons?.policy?.text || ''
    const buttonPolicyLink = buttons?.policy?.link?.slug || ''
    const address = contact?.address || ''
    const email = contact?.email || ''
    const tagInfo = data?.tagInfo || ''
    const tagLink = { text: data?.tagLink?.text || '', url: data?.tagLink?.url || ''}
    const { instance: { __lenis } } = useScrollContext()
	const {locale} = useRouter();
    const isMobile = useIsMobile()
    const { isShown, isCookiesModalExpanded } = useSelector(state => state.showCookies)
    const dispatch = useDispatch()

    return (
           <Wrapper data-cursor={CURSOR_TYPES.HTML} data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN}>
               <Video src={MockupVideo} autoplay />
               <Content className="px-main">
                   <Row className='justify-between'>
                       <Icon name={ICONS.ARROWRIGHT}/>
                       {!isMobile && (
                           <Logos logos={logos} />
                       )}
                   </Row>
                   <Row className="navigation">
                       <Column>
                           {links.map((link, index) =>{
							   let href = link.slug === 'blog' ? `https://pleo-ultimate-monorepo-group.vercel.app/${locale === 'pl' ? 'pl/' : ''}news` : `/${link?.slug || ''}`;
                               const finalHref = getSlug(href)
                               return(
                               <Link
                                   key={index}
                                   href={finalHref}
                                   target={link.slug === 'blog' ? "_blank" : '_self'}
                                   data-cursor={CURSOR_TYPES.LINK}
                                   className="underline-animation"
                               >
                                   <Reveal onInView>
                                       {link?.name || ''}
                                   </Reveal>
                               </Link>
                           )})}
                       </Column>
                       <Column className="lower">
                           {socials.map((social, index) => (
                               <Button
                                   as="a"
                                   key={index}
                                   href={social?.link || ''}
                                   target="_blank"
                                   className="arrow"
                                   variant="unstyled"
                                   data-cursor={CURSOR_TYPES.HIDE}
                               >
                                   <Reveal onInView>
                                       {social?.title || ''}
                                   </Reveal>
                                   <Icon name={ICONS.ARROWUP} color="black"/>
                               </Button>
                           ))}
                       </Column>
                   </Row>
                   {isMobile && (
                       <Logos logos={logos} />
                   )}
                   <Row className="bottom">
                       <Row className="pair ui">
                           <Button
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                               onClick={() => {
                                   __lenis.scrollTo(0, { lock: true })
                               }}
                           >
                               <Reveal onInView>
                                   {buttonTop}
                               </Reveal>
                           </Button>
                       </Row>
                       <Row className="pair contact">
                           <Button
                               as="a"
                               href={`https://maps.app.goo.gl/5kg8GMHZZG8Jc3A96`}
                               target="_blank"
                               className="arrow underline-animation"
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                           >
                               <Reveal onInView>
                                   {address}
                               </Reveal>
                           </Button>
                           <Button
                               as="a"
                               href={`mailto:${email}`}
                               className="arrow"
                               variant="unstyled"
                           >
                               <Reveal onInView>
                                   {email}
                               </Reveal>
                               <Icon name={ICONS.ARROWUP}/>
                           </Button>
                       </Row>
                       <Row className="pair policies">
                           <Button
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                               onClick={() => {
                                   if (isShown || isCookiesModalExpanded) {
                                       return
                                   }

                                   dispatch(setShowCookies(true))
                               }}
                           >
                               <Reveal onInView>
                                   {buttonCookies}
                               </Reveal>
                           </Button>
                           <Link
                               href={`/${buttonPolicyLink}`}
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                           >
                               <Reveal onInView>
                                   {buttonPolicy}
                               </Reveal>
                           </Link>
                           <LanguageChange/>
                       </Row>
                   </Row>
                   <PleonianinRive
                       data={{
                           tagInfo,
                           tagLink
                       }}
                   />
               </Content>
           </Wrapper>
    );
};

export default memo(Footer);
